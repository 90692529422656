export const user = {
  getUser: {
    url: "admin/getUser",
    method: "GET",
    params:null,      
  },
    getAllNormalUsers: {
      url: "adminUsers",
      method: "GET",
      params:null,      
    },
    getAllFollowers:{
      url: "admin/followersList",
      method: "GET",
      params:null,
    },
    getAllFollowings:{
      url: "admin/followingList",
      method: "GET",
      params:null,
    },
    getAllPosts:{
      url: "admin/posts/getAllPosts",
      method: "GET",
      params:null,
    },


  };
  