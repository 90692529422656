<template>
  <div class="card">
    <div class="card-body" v-if="data">
      <div class="d-flex justify-content-around">
        <b-avatar class="mr-2" size="58" :src="data.profile_image"></b-avatar>

        <div style="font-size: 0.8rem">
          <strong>Name: </strong> {{ data.fullname }} <br />
          <strong>Email: </strong> {{ data.email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style>
</style>