<template>
  <div>
    <div style="text-align: center; margin: 28px" v-if="dataLoading">
      <pulse-loader color="#e83e8c" />
    </div>
    <div class="row">
      <div class="col-sm-6 col-xl-4" v-for="item in tableData" :key="item._id">
        <userCard :data="item" />
      </div>
    </div>

    <div class="row" v-if="rows">
      <div class="col-sm-5">
        <div class="float-left">
          <label
            class="d-inline-flex align-items-center"
            style="min-width: 45px"
          >
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChange"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <div class="col-sm-7">
        <div class="float-right">
          <ul class="pagination pagination-rounded mb-4">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="pageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { user } from "@/config/api/users";
import userCard from "@/components/user/user-hr-card";

export default {
  components: {
    userCard,
  },
  data() {
    return {
      tableData: [],
      title: "",
      items: [
        {
          text: "Users",
          href: "/",
        },
        {
          text: "Normal",
          active: true,
        },
      ],
      rows: 0,
      currentPage: 1,
      currentUser: null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],

      fields: [
        { key: "user", sortable: false, label: "User" },
        { key: "nomadic", sortable: false, label: "Nomad" },
        { key: "Contact", sortable: false },
      ],
      dataLoading: false,
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getFollowingUsers();
  },
  methods: {
    getFollowingUsers() {
      this.dataLoading = true;
      this.tableData = [];
      const api = user.getAllFollowings;
      api.params = {
        userId: this.$store.state.normalUser.selected_user_id,
        page: this.currentPage,
        perPage: this.perPage,
        role: "Normal",
      };
      this.generateAPI(api)
        .then((res) => {
          this.tableData = res.data.following;
          this.rows = res.data.count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log | "Loaded";
        });
    },
    pageChange(val) {
      this.currentPage = val;
      this.getFollowingUsers();
    },
    perPageChange() {
      this.getFollowingUsers();
    },
  },
};
</script>


<style scoped>
</style>